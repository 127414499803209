import {Helmet} from "react-helmet";
import {useEffect} from "react";

export default function ({section, children}){

    useEffect(() => {
        document.getElementById('page').className = section;
    }, [section]);
    return <Helmet>
        {children}
    </Helmet>
}