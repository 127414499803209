import Helmet from "../../Helmet";
import Title from "../../Title";


export const Docker = () => {
    return <><Helmet section={'course'}>
        <title>Відео-курс "DOCKER для розробника" | Олег Муковоз</title>
        <meta name="description"
              content="Курс по Docker да Docker-Compose для розробника. Що таке докер, як створити свій власний контейнер, образ."/>
    </Helmet>

        <main className={'course'}>
            <Title><h1>DOCKER для розробника</h1></Title>
            <div className="content">
                <div className="description">
                    <p>
                        Уявити розробку без <strong>докеру</strong>, якщо вже попробував майже нереально. Це настільки зручна штука, яка
                        повністю
                        змінить ваш підхід до розробки та розгортування ваших додатків.
                    </p>
                    <p>
                        Цей відео-курс розроблений в <strong>першу чергу</strong> для розробників - для тих, хто хоче розібратися в тому,
                        що
                        таке
                        контейнери, образи, як їх створювати, запускати та будувати власні.
                    </p>
                    <p>
                        Також я <strong>навчу вас</strong> налаштовувати оточення за допомогою <strong>docker-compose</strong> для ваших проектів, чи
                        можливо
                        власних стартапів. Ми торкнемося всіх ньюансів, які будуть корисні вам, як для розробника.
                    </p>

                    <div className="buy">
                        {/*<div className={'price'}>749грн.</div>*/}
                        <span>Курс у розробці. Оріентовна дата виходу  - <strong>1 Серпня 2024.</strong> <br/> Підписуйтесь у <a href={'https://t.me/oleg_mukovoz'}>Telegram</a> , там буде інформація про реліз курсу та, звичайно промокоди зі знижками</span>
                        <br/>
                        <br/>
                        {/*<a style={{opacity:0.3}} className={'button'} href={'#'}>Придбати</a>*/}
                    </div>
                </div>
                <div className="content-table">
                    <span>Зміст відео курса</span>
                    <ul>
                        <li>Що таке докер, контейнер та образи</li>
                        <li>Створення контейнера</li>
                        <li>Проброс портів</li>
                        <li>Підключення volumes</li>
                        <li>Опції контейнерів</li>
                        <li>Побудова власного образу</li>
                        <li>Можливості Dockerfile</li>
                        <li>Що таке Docker-Compose</li>
                        <li>Можливості docker-compose.yml</li>
                        <li>Розробка environment</li>
                        <li>Кращі практики</li>
                        <li>Docker на продакшені</li>
                    </ul>
                </div>
            </div>
        </main>

    </>
}