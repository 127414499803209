import Title from "../Title";
import Helmet from "../Helmet";

export const Home = () => {
    return <><Helmet section={'home'}>
        <title>Олег Муковоз - FullStack WEB розробник</title>
    </Helmet>

        <main className={'home'}>
           <Title><h1>Привіт</h1></Title>
            <p>Мене звати <strong>Олег Муковоз</strong> і я є розробником у багатьох сферах. Також я маю
                низку <a
                    href={''}>власних проектів</a> та постійно запускаю нові. А ще я <a
                    href={'навчаю'}>навчаю</a> людей програмуванню і технологіям
                та веду <a title={'YouTube'} href={'https://www.youtube.com/@oleg.mukovoz'} target={"_blank"}>YouTube</a> і <a title={'Telegram'} target={"_blank"} href={'"https://t.me/oleg_mukovoz'}>Telegram</a> канали. </p>
        </main>


    </>
}