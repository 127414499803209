import logo from './logo.svg';
import telegram from './icons/hugeicons_telegram.svg';
import instagram from './icons/iconoir_instagram.svg';
import youtube from './icons/mingcute_youtube-line.svg';
import github from './icons/mingcute_github-line.svg';
import home from './icons/home.svg';
import './App.scss';
import MenuHandle from "./icons/MenuHandle";
import {useState} from "react";
import {BrowserRouter, Link, NavLink, Route, Routes} from "react-router-dom";
import {Helmet} from 'react-helmet'
import {Home} from "./Page/Home";
import {Docker} from "./Page/Course/Docker";


function App() {

    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <div id={'page'} className={'home'}>
            <BrowserRouter basename="/">
                <header>
                    <div>
                        <Link className={'logo'} to={"/"}><img alt={'mukovoz.com'} src={logo}/></Link>
                    </div>
                    <a className={'mobile-menu-handler'} onClick={e => setMenuOpen(!menuOpen)}>
                        <MenuHandle/>
                    </a>
                    <nav data-show={menuOpen}>
                        <div className="menu" onClick={e => setMenuOpen(false)}>
                            <ul>
                                <li>
                                    <NavLink to={"/"}><img src={home} alt={'home'}/></NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/ua/course/docker"}>Курс по DOCKER</NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/ua/projects"}>Розробки</NavLink>
                                </li>
                            </ul>
                        </div>

                        <div className="socials">
                            <ul>
                                <li><a target={"_blank"} href="https://t.me/oleg_mukovoz"><img src={telegram} alt={'Telegram'}/> </a></li>
                                <li><a  target={"_blank" } href="https://www.instagram.com/oleg.mukovoz"><img src={instagram} alt={'Instagram'}/> </a></li>
                                <li><a target={"_blank"} href="https://github.com/mukovoz"><img src={github} alt={'GitHub'}/> </a></li>
                                <li><a target={"_blank"} href="https://www.youtube.com/@oleg.mukovoz"><img src={youtube} alt={'YouTube'}/> </a></li>
                            </ul>
                        </div>
                    </nav>
                </header>


                <Routes>
                    <Route path={"/"} element={<Home/>}/>
                    <Route path={"/ua/course/docker"} element={<Docker/>}/>
                    <Route path={"/docker"} element={<Docker/>}/>
                </Routes>


            </BrowserRouter>
        </div>
    );
}

export default App;
